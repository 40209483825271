import React, { useContext } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Button,
  Box,
  TextField,
  FormHelperText,
  makeStyles,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { AuthContext } from "src/context/Auth";
import ButtonCircularProgress from "./ButtonCircularProgress";
// import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";

const useStyles = makeStyles(() => ({
  mainSpan: {
    "& span": {
      color: "Red",
    },
  },
}));

export default function AlertDialog({
  title,
  desc,
  isLoading,
  datat,
  productName,
  open,
  handleClose,
  productData,
  deleteProductHandler,
  deleteServiceHandler,
  BlockUnblockUser,
  valueData,
  viewdata,
  UserApproveReject,
  faqId,
  DeleteHandler,

  productsApproveReject,
  serviceApproveReject,
  CategoryBlockHander,
  Productblockhendler,
  producttitle,
  setReason,
  reason,
  isSubmit,
  serviceReject,
  setServiceReject,
  setVendorRejct,
  vendorRejct,
}) {
  const history = useHistory();
  const auth = useContext(AuthContext);

  const classes = useStyles();

  const iddddd = viewdata?._id;
  const logoutHandler = () => {
    history.push("/");
    auth.userLogIn(false, null);
    window.sessionStorage.removeItem("token");
  };
  return (
    <div>
      {title === "Logout" ? (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              {desc}
            </Typography>
            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={logoutHandler}
              >
                Confirm {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {productName === "Product"
                  ? "Product Delete"
                  : "Service Delete"}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to delete this
              {productName === "Product" ? "product ?" : "service ?"}
            </Typography>

            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (productName === "Product") {
                    deleteProductHandler(productData?._id);
                  }
                  if (productName === "Service") {
                    deleteServiceHandler(productData?._id);
                  }
                }}
              >
                Confirm {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}
      {title === "Block Unblock" && (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {valueData?.status === "ACTIVE" ? "BLOCK" : "UNBLOCK"}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to{" "}
              {valueData?.status === "ACTIVE" ? "Block ?" : "UnBlock ?"}
            </Typography>
            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={() => BlockUnblockUser()}
              >
                Confirm {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {datat === "Category Block Unblock" && (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {title}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to{" "}
              {valueData?.status === "ACTIVE" ? "Block ? " : "UnBlock ?"}
            </Typography>
            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                onClick={() => CategoryBlockHander()}
              >
                Confirm {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {title === "Deletefaq" && (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                Delete FAQ
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to Delete ?
            </Typography>
            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                style={{ marginLeft: "13px", padding: "7px 20px" }}
                variant="outlined"
                color="primary"
                // onClick={() => DeleteHandler}
                onClick={() => DeleteHandler(faqId?._id)}
              >
                Confirm {isLoading && <ButtonCircularProgress />}
              </Button>
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {title === "Approve Reject" && (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {desc === "APPROVED"
                  ? "APPROVED "
                  : desc === "REJECTED"
                  ? "REJECT "
                  : " "}{" "}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to{" "}
              {desc === "APPROVED"
                ? "Approve "
                : desc === "REJECTED"
                ? "Reject "
                : " "}{" "}
              the user?
            </Typography>
            {desc === "REJECTED" ? (
              <>
                <Box mt={2} mb={2} className={classes.mainSpan}>
                  <Typography variant="body2">
                    Reason<span>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Please enter reason"
                    multiline
                    rows={4}
                    onChange={(e) => {
                      setVendorRejct(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    value={vendorRejct}
                  />
                  <FormHelperText error>
                    {isSubmit && vendorRejct === "" && "Please  enter reason."}
                  </FormHelperText>
                </Box>
              </>
            ) : (
              ""
            )}

            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>

              {desc === "APPROVED" ? (
                <>
                  <Button
                    disabled={isLoading}
                    style={{ marginLeft: "13px", padding: "7px 20px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={UserApproveReject}
                  >
                    Confirm
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={isLoading || vendorRejct === ""}
                    style={{ marginLeft: "13px", padding: "7px 20px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={UserApproveReject}
                  >
                    Confirm
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {title === "Product Approve Reject" && (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {desc === "APPROVED"
                  ? "APPROVED "
                  : desc === "REJECTED"
                  ? "REJECT "
                  : " "}{" "}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to{" "}
              {desc === "APPROVED"
                ? "Approve "
                : desc === "REJECTED"
                ? "Reject "
                : " "}{" "}
              the product ?
            </Typography>

            {desc === "REJECTED" ? (
              <>
                <Box mt={2} mb={2} className={classes.mainSpan}>
                  <Typography variant="body2">
                    Reason <span>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Please enter reason"
                    multiline
                    rows={4}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    value={reason}
                  />
                  <FormHelperText error>
                    {isSubmit && reason === "" && "Please  enter reason."}
                  </FormHelperText>
                </Box>
              </>
            ) : (
              ""
            )}

            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>

              {desc === "APPROVED" ? (
                <>
                  <Button
                    disabled={isLoading}
                    style={{ marginLeft: "13px", padding: "7px 20px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={productsApproveReject}
                  >
                    Confirm {isLoading && <ButtonCircularProgress />}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={isLoading || reason === ""}
                    style={{ marginLeft: "13px", padding: "7px 20px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={productsApproveReject}
                  >
                    Confirm {isLoading && <ButtonCircularProgress />}
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {title === "Service Approve Reject" && (
        <Dialog
          open={open}
          onClose={() => {
            if (!isLoading) {
              handleClose();
            }
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
          maxWidth="xs"
        >
          <DialogContent>
            <Box align="center">
              <Typography
                variant="h3"
                style={{ color: "#404040", fontSize: "25px" }}
              >
                {desc === "APPROVED"
                  ? "APPROVED "
                  : desc === "REJECTED"
                  ? "REJECT "
                  : " "}{" "}
              </Typography>
            </Box>
            <Typography
              variant="h5"
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "16px",
                margin: "20px 0px",
                textAlign: "center",
              }}
            >
              Are you sure you want to{" "}
              {desc === "APPROVED"
                ? "Approve "
                : desc === "REJECTED"
                ? "Reject "
                : " "}{" "}
              the service ?
            </Typography>
            {desc === "REJECTED" ? (
              <>
                {" "}
                <Box mt={2} mb={2} className={classes.mainSpan}>
                  <Typography variant="body2">
                    Reason<span>*</span>
                  </Typography>
                  <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Please enter reason"
                    multiline
                    rows={4}
                    onChange={(e) => {
                      setServiceReject(e.target.value);
                    }}
                    onKeyPress={(event) => {
                      if (event?.key === "-" || event?.key === "+") {
                        event.preventDefault();
                      }
                    }}
                    value={serviceReject}
                  />
                  <FormHelperText error>
                    {isSubmit &&
                      serviceReject === "" &&
                      "Please  enter reason."}
                  </FormHelperText>
                </Box>
              </>
            ) : (
              ""
            )}

            <Box mb={2} align="center">
              <Button
                disabled={isLoading}
                variant="contained"
                color="secondary"
                onClick={handleClose}
                style={{ padding: "7px 23px" }}
              >
                Cancel
              </Button>
              {desc === "APPROVED" ? (
                <>
                  <Button
                    disabled={isLoading}
                    style={{ marginLeft: "13px", padding: "7px 20px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={serviceApproveReject}
                  >
                    Confirm {isLoading && <ButtonCircularProgress />}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    disabled={isLoading || serviceReject === ""}
                    style={{ marginLeft: "13px", padding: "7px 20px" }}
                    variant="outlined"
                    color="secondary"
                    onClick={serviceApproveReject}
                  >
                    Confirm {isLoading && <ButtonCircularProgress />}
                  </Button>
                </>
              )}
            </Box>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
