/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import { MdEmojiEvents, MdOutlineContactMail } from "react-icons/md";
import PropTypes from "prop-types";
import {
  Box,
  Drawer,
  Hidden,
  List,
  Button,
  ListSubheader,
  makeStyles,
} from "@material-ui/core";
import {
  AiOutlineDashboard,
  GiReceiveMoney,
  IoIosContact,
  RiLogoutCircleLine,
  RiQuestionnaireFill,
  BsFillCalendarEventFill,
  MdPets,
  MdTrackChanges,
  MdReportGmailerrorred,
  HiUserGroup,
} from "react-icons/all";
import { FaBoxOpen } from "react-icons/fa";
import { HiShoppingBag } from "react-icons/hi";
import NavItem from "./NavItem";
import { MdCategory } from "react-icons/md";
import { SiPuppet } from "react-icons/si";
import { RiAdvertisementFill } from "react-icons/ri";
import { MdMiscellaneousServices, MdDescription } from "react-icons/md";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import ViewCarouselIcon from "@material-ui/icons/ViewCarousel";
import { FaInternetExplorer } from "react-icons/fa";
import { VscFeedback } from "react-icons/vsc";

const sections = [
  {
    items: [
      {
        title: "Dashboard",
        icon: AiOutlineDashboard,
        href: "/admin-dashboard",
      },
      {
        title: "User Management",
        icon: HiUserGroup,
        href: "/user-management",
      },
      {
        title: "User Post Management",
        icon: AllInboxIcon,
        href: "/user-post-management",
      },
      {
        title: "Vendor Management",
        icon: HiShoppingBag,
        href: "/vendor-management",
      },
      {
        title: "Category Management",
        icon: MdCategory,
        href: "/category-management",
        items: [
          {
            title: "Category ",
            icon: MdCategory,
            href: "/category-management",
          },
          {
            title: "Sub Category ",
            icon: GiReceiveMoney,
            href: "/sub-category-management",
          },
        ],
      },
      {
        title: "Transaction Management",
        icon: MdEmojiEvents,
        href: "/transaction-management",
      },
      {
        title: "Product Management",
        icon: FaBoxOpen,
        href: "/product-management",
      },
      {
        title: "Service Management",
        icon: MdMiscellaneousServices,
        href: "/service-management",
      },
      {
        title: "Reward Management",
        icon: MdEmojiEvents,
        href: "/reward-management",
      },
      {
        title: "Event Management",
        icon: BsFillCalendarEventFill,
        href: "/event-management",
      },
      {
        title: "Missing Pet Management",
        icon: MdPets,
        href: "/missing-management",
      },
      {
        title: "Pet Type Management",
        icon: MdPets,
        href: "/pet-management",
      },{
        title: "Pet Breed Management",
        icon: MdPets,
        href: "/pet-breed-management",
      },
      {
        title: "Tracking Management",
        icon: MdTrackChanges,
        href: "/tracking-management",
      },
      {
        title: "Report Management",
        icon: MdReportGmailerrorred,
        href: "/report-management",
      },
      {
        title: "Banner & Ad Management",
        icon: ViewCarouselIcon,
        href: "/banner-management",
      },
      {
        title: "Explore Management",
        icon: FaInternetExplorer,
        href: "/explore-management",
      },
      {
        title: "Static Content Management",
        icon: MdDescription,
        href: "/static-content",
      },
      {
        title: "Faq Management",
        icon: RiQuestionnaireFill,
        href: "/Faq-management",
      },
      {
        title: "ContactUs Management",
        icon: VscFeedback,
        href: "/feedback-management",
      },
      {
        title: "ContactUs Details",
        icon: MdOutlineContactMail,
        href: "/contact-management",
      },

      {
        title: "My Account",
        icon: IoIosContact,
        href: "/view-profile",
      },
    ],
  },
];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }
  return acc;
}
const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 285,
    background: theme.palette.background.default,
  },
  desktopDrawer: {
    width: 250,
    // top: 72,
    top: 63,
    // left: "30px",
    height: "100%",
    background: "#ffff",
    borderRight: "0",
    boxShadow: "4px 3px 4px rgba(0, 0, 0, 0.1)",
    padding: "0px 20px",
  },
  avatar: {
    cursor: "pointer",
    width: 64,
    height: 64,
  },
  socialIcon: {
    cursor: "pointer",
    marginRight: 5,
  },
  button: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    height: "45px",
    paddingLeft: "17px",
    borderRadius: "12px",
    marginTop: "-30px",
    "&:hover": {
      color: "#6FCFB9",
    },
    "& svg": {
      color: "#6FCFB9",
      fontSize: "20px",
    },
  },
  LogoutBtn: {
    color: "#FE5151",
    borderRadius: "10px",
  },
  btnBox: {
    position: "relative",
    padding: "5px 0px 0px 5px",
    paddingLeft: "5px",
    margin: "0 0px 50px",
    borderRadius: "10px",
    cursor: "pointer",
    "&:hover": {
      background: "#6FCFB9",
      color: "#fff",
    },
  },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box py={4}>
          {sections.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
          <Box className={classes.btnBox} onClick={() => setOpen(true)}>
            <Button
              utton
              className={classes.LogoutBtn}
              // variant="contained"
              // color="primary"
            >
              <RiLogoutCircleLine />
              &nbsp;&nbsp;&nbsp;Logout
            </Button>
          </Box>
        </Box>
      </PerfectScrollbar>
      <ConfirmationDialog
        open={open}
        handleClose={() => setOpen(false)}
        title={"Logout"}
        desc={"Are you sure you want to Logout?"}
      />
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <Box p={2}>{content}</Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;
