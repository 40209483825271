import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import AdminLayout from "src/layouts/AdminLayout";
import HomeLayout from "src/layouts/HomeLayout";
import LoginLayout from "src/layouts/LoginLayout";
export const routes = [
  // {
  //   exact: true,
  //   path: "/",
  //   layout: LoginLayout,
  //   component: lazy(() => import("src/views/auth/LoginPage")),
  // },
  {
    exact: true,
    path: "/",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/reset-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetOtp")),
  },
  {
    exact: true,
    path: "/signup",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/signup/signup")),
  },
  {
    exact: true,
    path: "/forgot-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/forget-password/index")),
  },
  {
    exact: true,
    path: "/check-email",
    // guard:true,
    component: lazy(() => import("src/views/auth/signup/CheckEmail")),
  },

  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/reset-password/index")),
  },
  {
    guard: true,
    exact: true,
    path: "/admin-dashboard",
    layout: AdminLayout,
    component: lazy(() => import("src/views/pages/AdminSection/Dashboard")),
  },
  {
    exact: true,
    path: "/product-tabs",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/ServiceTab")
    ),
  },
  {
    exact: true,
    path: "/view-vandor-service",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/ViewVandorService")
    ),
  },

  {
    exact: true,
    path: "/interested-client-servcie",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/VendorManagement/InterestServicetabs"
      )
    ),
  },
  {
    exact: true,
    path: "/vendor-chat",
    layout: AdminLayout,
    component: lazy(() => import("src/views/pages/AdminSection/Chat")),
  },
  {
    exact: true,
    path: "/view-profile",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/AdminProfile/ViewProfile")
    ),
  },
  {
    exact: true,
    path: "/change-password",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/AdminProfile/ChangePassword")
    ),
  },

  {
    exact: true,
    path: "/userhistorylist",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/RewardManagement/UserHistoryList")
    ),
  },

  {
    guard: true,
    exact: true,
    path: "/user-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement")
    ),
  },
  {
    guard: true,
    exact: true,
    path: "/user-post-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserPostManagement/UserPostList")
    ),
  },
  {
    exact: true,
    path: "/vendor-add-edit-product",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/AddEditProduct")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/service-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ServiceManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-vender-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/ServiceTab")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/product-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ProductManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-product",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ProductManagement/ViewProduct")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-service",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ServiceManagement/ViewService")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-sub-category",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/SubCategoryManagement/AddSubCategory"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-sub-category",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/SubCategoryManagement/ViewSubCategory"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-sub-category",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/SubCategoryManagement/EditSubCategory"
      )
    ),
  },
  {
    exact: true,
    path: "/vendor-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement")
    ),
  },

  {
    exact: true,
    path: "/new-vendor-requested",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/Viewnewvendor")
    ),
  },

  {
    exact: true,
    path: "/new-vendor-request",
    layout: AdminLayout,

    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/NewVendor")
    ),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/AdminProfile/index")
    ),
  },
  {
    exact: true,
    path: "/vendor-setting",
    layout: AdminLayout,
    component: lazy(() => import("src/views/pages/AdminSection/VendorSetting")),
  },
  {
    exact: true,
    guard: true,
    path: "/reward-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/RewardManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/event-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/EventManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/missing-management",
    layout: AdminLayout,
    component: lazy(() => import("src/views/pages/AdminSection/MissingPet")),
  },
  {
    exact: true,
    guard: true,
    path: "/tracking-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/TrackingManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/report-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/Reportmanagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/feedback-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FeedbackManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/contact-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ContactManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/static-content",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/StaticContent")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/Faq-management",
    layout: AdminLayout,
    component: lazy(() => import("src/views/pages/AdminSection/FaqManagement")),
  },
  {
    exact: true,
    path: "/About-us",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/AboutUs")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/View-Faq",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/ViewFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-Faq",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/AddFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-Faq",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/FaqManagement/EditFaq")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/faq",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/Faq")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-static-content-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/EditPrivacyPolicy")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-terms-condition",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/EditTerms")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/edit-about-us",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/EditAboutUs")
    ),
  },
  {
    exact: true,
    path: "/terms-condition",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/TermsAndCondition")
    ),
  },
  {
    exact: true,
    path: "/edit",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContentManagement/EditFile")
    ),
  },
  // {
  //   exact: true,
  //   path: "/vendor-notification",
  //   layout: AdminLayout,
  //   component: lazy(() =>
  //     import("src/views/pages/Notification/VendorNotification")
  //   ),
  // },
  {
    exact: true,
    path: "/add-services",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/NewVendor")
    ),
  },
  {
    exact: true,
    path: "/edit-services",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/VendorManagement/EditService")
    ),
  },
  {
    exact: true,
    path: "/account-information",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/HiddenAccount")
    ),
  },
  {
    exact: true,
    path: "/edit-product",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/EditProduct")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/viewevent",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/EventManagement/ViewEvents")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/viewemissing",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/MissingPet/View")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/viewreport",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/Reportmanagement/View")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/edit-category",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/CategoryManagement/EditCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/reqested-service-list",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/ServiceManagement/RequestedServiceList"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-reqested-service-list",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/ServiceManagement/RequestedServiceList/ViewRequestedService"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-category",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/CategoryManagement/AddCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/userTabs",
    layout: AdminLayout,
    component: lazy(() => import("src/component/UserTabs")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-list",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/ProductManagement/RequestedProductList/Productlist"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-category",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/CategoryManagement/ViewCategory")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-requested-product-list",
    layout: AdminLayout,
    component: lazy(() =>
      import(
        "src/views/pages/AdminSection/ProductManagement/RequestedProductList/ViewRequestList"
      )
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-user-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/UserManagement/ViewUser")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/category-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/CategoryManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/sub-category-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/SubCategoryManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/pet-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/PetSection/PetManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/pet-breed-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/PetBreedSection/PetManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-pet-breed-category",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/PetBreedSection/AddPetCategory.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-pet-category",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/PetSection/AddPetCategory.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/banner-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/BannerManagement/BannerList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-banner",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/BannerManagement/AddEditBanner")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/update-contact-information",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ContactManagement/UpdateContect.js")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-banner",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/BannerManagement/ViewBanner")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/explore-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ExploreManagement/ExploreList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/AddEdit-explore",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ExploreManagement/AddEditExplore")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/view-explore",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/ExploreManagement/ViewExplore")
    ),
  },

  {
    exact: true,
    guard: true,
    path: "/ad-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/Ads.Management/AdsAllList")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/transaction-management",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/TransactionManagement")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/add-edit-ad",
    layout: AdminLayout,
    component: lazy(() =>
      import("src/views/pages/AdminSection/Ads.Management/AddEdit")
    ),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
