export const baseurl = process.env.REACT_APP_BASE_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_PLACES_KEY;

let admin = `${baseurl}/api/v1/admin`;
let post = `${baseurl}/api/v1/post`;
let product = `${baseurl}/api/v1/product`;
let category = `${baseurl}/api/v1/category`;
let statics1 = `${baseurl}/api/v1/static`;
let statics = `${baseurl}/api/v1/static/static`;
let pet = `${baseurl}/api/v1/pet`;

let user = `${baseurl}/api/v1/user`;
let contact = `${baseurl}/api/v1/contact`;

const sta = `${baseurl}/api/v1/static`;

const Apiconfigs = {
  profile: `${admin}/profile`,
  createCategory: `${category}/createCategory`,
  createSubCategory: `${category}/createSubCategory`,
  updateCategory: `${category}/updateCategory`,

  login: `${admin}/login`,
  verifyOTP: `${admin}/verifyOTP`,
  resendOTP: `${admin}/resendOTP`,
  uploadFile: `${post}/uploadMultipleFile`,
  editProfile: `${admin}/editProfile`,
  changePassword: `${admin}/changePassword`,
  forgotPassword: `${admin}/forgotPassword`,
  resetPassword: `${admin}/resetPassword`,
  adminDashboard: `${admin}/adminDashboard`,
  registeredUserChart: `${admin}/registeredUserChart`,
  approveRejectUser: `${admin}/approveRejectUser`,
  productListing: `${admin}/productListing`,
  vendorServiveOrProductDashboard: `${admin}/vendorServiveOrProductDashboard`,
  graphForProducts: `${admin}/graphForProducts`,
  listUpcomingEvent: `${admin}/listUpcomingEvent`,
  categoryChart: `${admin}/categoryChart`,
  missingPetChart: `${admin}/missingPetChart`,
  reportChart: `${admin}/reportChart`,
  subCategoryChart: `${admin}/subCategoryChart`,
  blockUnblockEvent: `${admin}/blockUnblockEvent`,
  blockUnblockReport: `${admin}/blockUnblockReport`,
  deletedUserList: `${admin}/deletedUserList`,

  //  USER MANAGEMENT
  listUser: `${admin}/listUser`,
  listAllPost: `${admin}/listAllPost`,
  viewUserviewUser: `${admin}/viewUser`,
  activeBlockUser: `${admin}/activeBlockUser`,
  listVendorsV2: `${admin}/listVendorsV2`,
  getAllDetailsForParticularUser: `${admin}/getAllDetailsForParticularUser`,

  //  CATEGORY MANAGEMENT
  listCategory: `${category}/listCategory`,
  createCategory: `${category}/createCategory`,
  categoryBlockUnblock: `${admin}/categoryBlockUnblock`,
  //  VENDER MANAGEMENT
  listVendor: `${user}/listVendor`,

  deleteVendor: `${admin}/deleteVendor`,

  blockUnblockVendor: `${admin}/blockUnblockVendor`,
  reportList: `${admin}/reportList`,
  AcptDeclineReport: `${admin}/AcptDeclineReport`,

  //  PRODUCT MANAGEMENT
  productListing: `${admin}/productListing`,
  blockUnblockProduct: `${admin}/blockUnblockProduct`,
  missingPetList: `${admin}/missingPetList`,
  approveRejectProductService: `${admin}/approveRejectProductService`,

  //  SERVICE MANAGEMENT
  serviceListing: `${admin}/serviceListing`,
  serviceBlockUnblock: `${admin}/serviceBlockUnblock`,

  // SUB CATEGORY MANAGEMENT
  listSubCategory: `${category}/listSubCategory`,
  updateSubCategory: `${category}/updateSubCategory`,
  subCategoryBlockUnblock: `${admin}/subCategoryBlockUnblock`,
  // STATIC MANAGEMENT
  staticContentList: `${sta}/static`,
  editStaticContent: `${sta}/static`,
  faqList: `${sta}/faqList`,
  addFAQ: `${sta}/addFAQ`,
  editFAQ: `${sta}/editFAQ`,
  deleteFAQ: `${sta}/deleteFAQ`,
  //  Event MANAGEMENT
  listEvent: `${admin}/listEvent`,
  deleteEvent: `${admin}/deleteEvent`,

  // Rewards Section
  userRewardListing: `${admin}/userRewardListing`,
  viewRewardsPoints: `${admin}/viewRewardsPoints`,
  redeemList: `${admin}/redeemList`,
  listInterested: `${user}/listInterested`,
  updatePoints: `${admin}/updatePoints`,

  //transaction section
  listTransaction: `${admin}/listTransaction`,

  // Pet Section
  trackPet: `${pet}/trackPet`,
  petCategoryList: `${pet}/petCategoryList`,
  petBreedList: `${pet}/petBreedList`,
  petBreed: `${pet}/petBreed`,
  petBreedDelete: `${pet}/petBreedDelete`,
  petBreedUpdate: `${pet}/petBreedUpdate`,
  addPetType: `${pet}/petCategory`,
  petCategoryDelete: `${pet}/petCategoryDelete`,
  petCategoryUpdate: `${pet}/petCategoryUpdate`,
  xlsxUpload: `${admin}/xlsxUpload`,
  addMoreDays: `${admin}/addMoreDays`,

  // statics
  updateContactDetails: `${statics1}/updateContactDetails`,
  contact_us_details: `${statics}/contact_us_details`,

  listBanner: `${admin}/listBanner`,
  addBanner: `${admin}/addBanner`,
  viewBanner: `${admin}/viewBanner`,
  deleteBanner: `${admin}/deleteBanner`,
  editBanner: `${admin}/editBanner`,
  listContactUs: `${contact}/listContactUs`,

  // Explore Management
  listExplore: `${admin}/listExplore`,
  addExplore: `${admin}/addExplore`,
  viewExplore: `${admin}/viewExplore`,
  deleteExplore: `${admin}/deleteExplore`,
  editExplore: `${admin}/editExplore`,
};
export default Apiconfigs;
